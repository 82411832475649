import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import VueOnsen from 'vue-onsenui';
import VueCookies from 'vue-cookies';

import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import "@/css/styles.css"

const firebaseConfig = {
  apiKey: "AIzaSyA8A5p6aaK3bTquIowQ2QeFiNii8_66xVE",
  authDomain: "a.enpuri.jp",
  projectId: "enpuri-2021",
  storageBucket: "enpuri-2021.appspot.com",
  messagingSenderId: "102303280716",
  appId: "1:102303280716:web:307baeff2dc6048febcee9",
  measurementId: "G-GRMH3WVXZ2"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app); // eslint-disable-line no-unused-vars

Vue.use(VueOnsen);
Vue.use(VueCookies);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    // デザインが崩れるためiosに固定
    this.$ons.platform.select("ios");
  },
  methods: {
    showModal() {
      this.$children[0].showModal();
    },
    hideModal() {
      this.$children[0].hideModal();
    }
  }
}).$mount('#app')
