export default {
    PRICE_980: 'price_1MTlsKIUyjivbk8S96gXBuBA',
    PRICE_680: 'price_1MTlsSIUyjivbk8Sm0qO9B9p',
    PRICE_480: 'price_1MTlsXIUyjivbk8S8ucKDHx1',
    PRICE_280: 'price_1MTlscIUyjivbk8Sj6gUKysE'
}

/* dev
export default {
    PRICE_980: 'price_1MTlmiIUyjivbk8SawIAwTjb',
    PRICE_680: 'price_1MTlmQIUyjivbk8SH0ZgthFA',
    PRICE_480: 'price_1MTlm3IUyjivbk8SCnEjxtmL',
    PRICE_280: 'price_1MTlkwIUyjivbk8SOUF6bDcv'
}
*/