<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button>戻る</v-ons-back-button>
      </div>
      <div class="center">
        <img src="img/kogi/header_logo.png" height="44" />
      </div>
      <div class="right">
        <span class="home" @click="popTopPage"><i class="fa fa-home" aria-hidden="true"></i></span>
      </div>
    </v-ons-toolbar>
    <div>
      <v-ons-list>
        <template  v-for="(kougi, index) in print.kougis">
          <v-ons-list-header
            v-bind:key="'h.' + kougi.kid"
            v-if="index == 0 || kougi.hn != print.kougis[index - 1].hn"
            class="l1"
          >
            {{ kougi.hn }}
          </v-ons-list-header>
          <v-ons-list-item
            v-bind:key="kougi.kid"
            @click="push(kougi)"
            tappable
            modifier="chevron"
          >
            <div class="center">
              <img
                :src="'img/kogi/' + kougi.uid + '/' + kougi.kuid + '.jpg'"
                height="80px"
                width="142px"
                style="margin: 0px 20px 0px 0px"
              />
              <div class="page_title2">{{ kougi.kn }}</div>
            </div>
            <div class="right" style="width: 82px">
              <div style="width: 78px; margin-left: 4px">
                <span class="notification v1 bg" v-if="kougi.record['1']"
                  >完璧</span
                >
                <span class="notification v2 bg" v-if="kougi.record['2']"
                  >理解</span
                >
                <span class="notification v3 bg" v-if="kougi.record['3']"
                  >復習</span
                >
              </div>
            </div>
          </v-ons-list-item>
        </template>
      </v-ons-list>
    </div>
  </v-ons-page>
</template>

<script>
import kougiPage from "./KougiPage.vue";

export default {
  mounted: function () {
    // console.log("Show Kougi Print Page");
  },
  methods: {
    popTopPage() {
      this.$emit("top-page");
    },
    push(kougi) {
      this.$emit("push-page", {
        extends: kougiPage,
        data() {
          return {
            kougi: kougi,
          };
        },
      });
    },
  },
};
</script>
