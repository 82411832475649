<template>
  <v-ons-page id="highpuri">
    <v-ons-toolbar style="height: 128px">
      <div class="center">
        <div style="height: 44px;">
          <img src="img/high/header_logo.png" height="44" />
        </div>
        <div style="height: 84px">
          <div
            class="center"
            style="font-size: 14px; font-weight: 400; margin-top: 3px"
          >
            <div style="height: 36px">
              <div class="segment" style="width: 320px">
                <div class="segment__item">
                  <input
                    type="radio"
                    class="segment__input"
                    name="segment"
                    v-model="segment"
                    value="1"
                  />
                  <div class="segment__button">準備系</div>
                </div>
                <div class="segment__item">
                  <input
                    type="radio"
                    class="segment__input"
                    name="segment"
                    v-model="segment"
                    value="2"
                  />
                  <div class="segment__button">図形系</div>
                </div>
                <div class="segment__item">
                  <input
                    type="radio"
                    class="segment__input"
                    name="segment"
                    v-model="segment"
                    value="3"
                  />
                  <div class="segment__button">関数系</div>
                </div>
                <div class="segment__item">
                  <input
                    type="radio"
                    class="segment__input"
                    name="segment"
                    v-model="segment"
                    value="4"
                  />
                  <div class="segment__button">確率統計系</div>
                </div>
              </div>
            </div>
            <div style="height: 36px">
              <div class="segment" style="width: 160px">
                <div class="segment__item">
                  <input
                    type="radio"
                    class="segment__input"
                    name="segment"
                    v-model="segment"
                    value="5"
                  />
                  <div class="segment__button">整数問題</div>
                </div>
                <div class="segment__item">
                  <input
                    type="radio"
                    class="segment__input"
                    name="segment"
                    v-model="segment"
                    value="6"
                  />
                  <div class="segment__button">数学Ⅲ</div>
                </div>
              </div>
              <div class="segment" style="width: 80px; margin-left: 80px">
                <div class="segment__item">
                  <input
                    type="radio"
                    class="segment__input"
                    name="segment"
                    v-model="segment"
                    value="0"
                  />
                  <div class="segment__button" style="border-radius: 4px">
                    すべて
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-ons-toolbar>
    <v-ons-list>
      <template v-for="(print, index) in units">
        <v-ons-list-header
          v-bind:key="'h.' + print.uid"
          v-if="index == 0 || print.hn != units[index - 1].hn"
          class="l1"
        >
          <div class="page_title">{{ print.hn }}</div>
        </v-ons-list-header>
        <v-ons-list-item
          v-bind:key="print.uid"
          @click="push(print)"
          tappable
          modifier="chevron"
        >
          <div class="center">
            <img
              :src="'img/high/unit/' + print.uid + '.png'"
              width="50px"
              height="50px"
              style="margin: 0px 20px 0px 20px"
            />
            <div class="page_title">{{ print.un }}</div>
          </div>
          <div class="right" style="display: block; height: 80px;">
            <div style="text-align: right; padding-top: 9px;">{{ percentage(print) }}</div>
            <div v-if="percentage(print)" style="width: 64px;">
              <span class="notification v1">{{ print.record["1"] }}</span>
              <span class="notification v2">{{ print.record["2"] }}</span>
              <span class="notification v3">{{ print.record["3"] }}</span>
            </div>
          </div>
        </v-ons-list-item>
      </template>
    </v-ons-list>
  </v-ons-page>
</template>

<style>
#all-button > .segment__item:last-child > .segment__button {
  border-radius: 4px !important;
}
#highpuri .toolbar+.page__background+.page__content {
  top: 128px!important;
}
</style>

<script>
import unitPage from "./UnitPage.vue";

export default {
  data() {
    return {
      segment: 0,
      allUnits: this.$store.state.highpuris,
      units: [],
    };
  },
  created: function () {
    this.segment = parseInt(localStorage.getItem("highpuri.segment") || "0", 10);
    this.filter();
  },
  methods: {
    filter: function() {
      let segment = this.segment;
      this.units = this.allUnits.filter(function (unit) {
        return (
          (segment == 1 && unit.did == "1") ||
          (segment == 2 && unit.did == "2") ||
          (segment == 3 && unit.did == "3") ||
          (segment == 4 && unit.did == "4") ||
          (segment == 5 && unit.did == "5") ||
          (segment == 6 && unit.did == "6") ||
          segment == 0
        );
      });
    },
    push(unit) {
      this.$emit("push-page", {
        extends: unitPage,
        data() {
          return {
            unit: unit,
          };
        },
      });
    },
    percentage(unit) {
      if (unit.record && unit.record["sum"]) {
        return (
          unit.record["1"] +
          unit.record["2"] +
          unit.record["3"] +
          " / " +
          unit.record["sum"]
        );
      }
    },
  },
  watch: {
    segment: function (val) {
      this.filter();
      localStorage.setItem("highpuri.segment", val);
    },
  },
};
</script>
