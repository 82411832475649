<template>
  <v-ons-page>
    <v-ons-toolbar style="background-color: white">
      <div class="center">
        <img src="img/settings/header_logo.png" height="44" />
      </div>
    </v-ons-toolbar>
    <v-ons-list>
      <v-ons-list-header class="l1">ログイン情報</v-ons-list-header>
      <v-ons-list-item @click="pushLoginPage" tappable modifier="chevron">
        <div class="left">
          <img class="list-item__thumbnail" style="margin-left: 14px!important;" :src="$store.state.loginUser.photoURL">
        </div>
        <div class="center">
          <span class="list-item__title">{{ $store.state.loginUser.userName }}</span>
          <span class="list-item__subtitle">{{ $store.state.loginUser.email }}</span>
        </div>
      </v-ons-list-item>

      <v-ons-list-header class="l1">現在の契約プラン</v-ons-list-header>

      <v-ons-list-item v-if="$store.state.loginUser.contractId == 9">
        <div style="padding: 20px;">
          <div class="contract_title">ボリュームライセンス（はいぷり、なんぷり）</div>
          <div class="contract_desc">
            <div style="margin-bottom: 8px;">講義動画（ⅠA、ⅡBC、Ⅲ）とえんぷり（ⅠA、ⅡBC、Ⅲ）及び、はいぷり、なんぷりの演習問題が学習可能です。</div>
            <div><div style="float: left; width: 180px;">・講義動画（ⅠA、ⅡBC、Ⅲ）</div><div>386 動画</div></div>
            <div><div style="float: left; width: 180px;">・えんぷり（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
            <div><div style="float: left; width: 180px;">・えんぷく（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
            <div><div style="float: left; width: 180px;">・ぷくぷく（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
            <div><div style="float: left; width: 180px;">・はいぷり</div><div>261 問</div></div>
            <div><div style="float: left; width: 180px;">・なんぷり</div><div>33 問</div></div>
          </div>
        </div>
      </v-ons-list-item>

      <v-ons-list-item v-if="$store.state.loginUser.contractId == 8">
        <div style="padding: 20px;">
          <div class="contract_title">ボリュームライセンス（えんぷり）</div>
          <div class="contract_desc">
            <div style="margin-bottom: 8px;">講義動画（ⅠA、ⅡBC、Ⅲ）とえんぷり（ⅠA、ⅡBC、Ⅲ）の演習問題が学習可能です。</div>
            <div><div style="float: left; width: 180px;">・講義動画（ⅠA、ⅡBC、Ⅲ）</div><div>386 動画</div></div>
            <div><div style="float: left; width: 180px;">・えんぷり（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
            <div><div style="float: left; width: 180px;">・えんぷく（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
            <div><div style="float: left; width: 180px;">・ぷくぷく（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
            <div><div style="float: left; width: 180px;">・はいぷり</div><div>0 問</div></div>
            <div><div style="float: left; width: 180px;">・なんぷり</div><div>0 問</div></div>
          </div>
        </div>
      </v-ons-list-item>

      <v-ons-list-item @click="pushPaymentPage" tappable modifier="chevron" v-if="$store.state.loginUser.contractId == 5">
        <div style="padding: 20px;">
          <div class="contract_title">プレミアム</div>
          <div class="contract_desc">
            <div v-if="$store.state.loginUser.cancelAtPeriodEnd">契約状況: <span style="color: red;">解約済み</span>（契約期間内は学習可能）</div>
            <div v-if="!$store.state.loginUser.cancelAtPeriodEnd">契約状況: 契約中</div>
            <div>月額料金: 980円 / 月</div>
            <div>契約期間: {{ formatDate($store.state.loginUser.start) }} 〜 {{ formatDate($store.state.loginUser.end) }}</div>
          </div>
          <div class="contract_desc">
            <div style="margin-bottom: 8px;">講義動画（ⅠA、ⅡBC、Ⅲ）とえんぷり（ⅠA、ⅡBC、Ⅲ）及び、はいぷり、なんぷりの演習問題が学習可能です。</div>
            <div><div style="float: left; width: 180px;">・講義動画（ⅠA、ⅡBC、Ⅲ）</div><div>386 動画</div></div>
            <div><div style="float: left; width: 180px;">・えんぷり（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
            <div><div style="float: left; width: 180px;">・えんぷく（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
            <div><div style="float: left; width: 180px;">・ぷくぷく（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
            <div><div style="float: left; width: 180px;">・はいぷり</div><div>261 問</div></div>
            <div><div style="float: left; width: 180px;">・なんぷり</div><div>33 問</div></div>
          </div>
        </div>
      </v-ons-list-item>

      <v-ons-list-item @click="pushPaymentPage" tappable modifier="chevron" v-if="$store.state.loginUser.contractId == 4">
        <div style="padding: 20px;">
          <div class="contract_title">スタンダード（ⅠA、ⅡBC、Ⅲ）</div>
          <div class="contract_desc">
            <div v-if="$store.state.loginUser.cancelAtPeriodEnd">契約状況: <span style="color: red;">解約済み</span>（契約期間内は学習可能）</div>
            <div v-if="!$store.state.loginUser.cancelAtPeriodEnd">契約状況: 契約中</div>
            <div>月額料金: 680円 / 月</div>
            <div>契約期間: {{ formatDate($store.state.loginUser.start) }} 〜 {{ formatDate($store.state.loginUser.end) }}</div>
          </div>
          <div class="contract_desc">
            <div style="margin-bottom: 8px;">講義動画（ⅠA、ⅡBC、Ⅲ）とえんぷり（ⅠA、ⅡBC、Ⅲ）の演習問題が学習可能です。</div>
            <div><div style="float: left; width: 180px;">・講義動画（ⅠA、ⅡBC、Ⅲ）</div><div>386 動画</div></div>
            <div><div style="float: left; width: 180px;">・えんぷり（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
            <div><div style="float: left; width: 180px;">・えんぷく（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
            <div><div style="float: left; width: 180px;">・ぷくぷく（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
            <div><div style="float: left; width: 180px;">・はいぷり</div><div>0 問</div></div>
            <div><div style="float: left; width: 180px;">・なんぷり</div><div>0 問</div></div>
          </div>
        </div>
      </v-ons-list-item>

      <v-ons-list-item @click="pushPaymentPage" tappable modifier="chevron" v-if="$store.state.loginUser.contractId == 3">
        <div style="padding: 20px;">
          <div class="contract_title">スタンダード（ⅠA、ⅡBC）</div>
          <div class="contract_desc">
            <div v-if="$store.state.loginUser.cancelAtPeriodEnd">契約状況: <span style="color: red;">解約済み</span>（契約期間内は学習可能）</div>
            <div v-if="!$store.state.loginUser.cancelAtPeriodEnd">契約状況: 契約中</div>
            <div>月額料金: 480円</div>
            <div>契約期間: {{ formatDate($store.state.loginUser.start) }} 〜 {{ formatDate($store.state.loginUser.end) }}</div>
          </div>
          <div class="contract_desc">
            <div style="margin-bottom: 8px;">講義動画（ⅠA、ⅡBC）とえんぷり（ⅠA、ⅡBC）の演習問題が学習可能です。</div>
            <div><div style="float: left; width: 180px;">・講義動画（ⅠA、ⅡBC）</div><div>294 動画</div></div>
            <div><div style="float: left; width: 180px;">・えんぷり（ⅠA、ⅡBC）</div><div>630 問</div></div>
            <div><div style="float: left; width: 180px;">・えんぷく（ⅠA、ⅡBC）</div><div>630 問</div></div>
            <div><div style="float: left; width: 180px;">・ぷくぷく（ⅠA、ⅡBC）</div><div>630 問</div></div>
            <div><div style="float: left; width: 180px;">・はいぷり</div><div>0 問</div></div>
            <div><div style="float: left; width: 180px;">・なんぷり</div><div>0 問</div></div>
          </div>
        </div>
      </v-ons-list-item>

      <v-ons-list-item @click="pushPaymentPage" tappable modifier="chevron" v-if="$store.state.loginUser.contractId == 2">
        <div style="padding: 20px;">
          <div class="contract_title">スタンダード（ⅠA）</div>
          <div class="contract_desc">
            <div v-if="$store.state.loginUser.cancelAtPeriodEnd">契約状況: <span style="color: red;">解約済み</span>（契約期間内は学習可能）</div>
            <div v-if="!$store.state.loginUser.cancelAtPeriodEnd">契約状況: 契約中</div>
            <div>月額料金: 280円 / 月</div>
            <div>契約期間: {{ formatDate($store.state.loginUser.start) }} 〜 {{ formatDate($store.state.loginUser.end) }}</div>
          </div>
          <div class="contract_desc">
            <div style="margin-bottom: 8px;">講義動画（ⅠA）とえんぷり（ⅠA）の演習問題が学習可能です。</div>
            <div><div style="float: left; width: 180px;">・講義動画（ⅠA）</div><div>120 動画</div></div>
            <div><div style="float: left; width: 180px;">・えんぷり（ⅠA）</div><div>262 問</div></div>
            <div><div style="float: left; width: 180px;">・えんぷく（ⅠA）</div><div>262 問</div></div>
            <div><div style="float: left; width: 180px;">・ぷくぷく（ⅠA）</div><div>262 問</div></div>
            <div><div style="float: left; width: 180px;">・はいぷり</div><div>0 問</div></div>
            <div><div style="float: left; width: 180px;">・なんぷり</div><div>0 問</div></div>
          </div>
        </div>
      </v-ons-list-item>

      <v-ons-list-item @click="pushPaymentPage" tappable modifier="chevron" v-if="$store.state.loginUser.contractId == 1">
        <div style="padding: 20px;">
          <div class="contract_title">無料ユーザー（無料）</div>
          <div class="contract_desc">
            <div style="margin-bottom: 8px;">講義動画（ⅠA）とえんぷり（ⅠA）の「数と式・集合と論理」の演習問題が学習可能です。</div>
            <div><div style="float: left; width: 180px;">・講義動画（ⅠA）</div><div>120 動画</div></div>
            <div><div style="float: left; width: 180px;">・えんぷり（ⅠAの一部）</div><div>60 問</div></div>
            <div><div style="float: left; width: 180px;">・えんぷく（ⅠAの一部）</div><div>60 問</div></div>
            <div><div style="float: left; width: 180px;">・ぷくぷく（ⅠAの一部）</div><div>60 問</div></div>
            <div><div style="float: left; width: 180px;">・はいぷり</div><div>0 問</div></div>
            <div><div style="float: left; width: 180px;">・なんぷり</div><div>0 問</div></div>
          </div>
        </div>
      </v-ons-list-item>

      <v-ons-list-item v-if="$store.state.loginUser.contractId == 0">
        <div style="padding: 20px;">
          <div class="contract_title">お試し（無料）</div>
          <div class="contract_desc">
            <div style="margin-bottom: 8px;">講義動画（ⅠA）とえんぷり（ⅠA）の「数と式・集合と論理」/「展開と因数分解」の演習問題が学習可能です。</div>
            <div><div style="float: left; width: 180px;">・講義動画（ⅠA）</div><div>120 動画</div></div>
            <div><div style="float: left; width: 180px;">・えんぷり（ⅠAの一部）</div><div>17 問</div></div>
            <div><div style="float: left; width: 180px;">・えんぷく（ⅠAの一部）</div><div>17 問</div></div>
            <div><div style="float: left; width: 180px;">・ぷくぷく（ⅠAの一部）</div><div>17 問</div></div>
            <div><div style="float: left; width: 180px;">・はいぷり</div><div>0 問</div></div>
            <div><div style="float: left; width: 180px;">・なんぷり</div><div>0 問</div></div>
            <div style="margin: 8px 0px 8px 0px; font-weight: bold;">無料のユーザー登録（上のゲストをクリック）を行うことで、「数と式・集合と論理」の演習問題が学習可能となります。</div>
          </div>
        </div>
      </v-ons-list-item>

      <v-ons-list-header class="l1" v-if="$store.state.loginUser.contractId != 0">ダウンロードサービス</v-ons-list-header>

      <v-ons-list-item @click="pushDownloadPage" tappable modifier="chevron" v-if="$store.state.loginUser.contractId != 0">
        <div style="padding: 20px;">
          教材のダウンロード（PDFファイル）
        </div>
      </v-ons-list-item>

      <v-ons-list-header class="l1">お問い合わせ（{{ localVersion }}）</v-ons-list-header>

      <v-ons-list-item @click="mailto" tappable modifier="chevron">
        <div style="padding: 20px;">
          お問い合わせ
        </div>
      </v-ons-list-item>

    </v-ons-list>
    <div style="padding: 20px; font-size: 14px;">
      <div style="text-align: center;">
        <a href="termsOfService.html" target="_blank">利用規約</a> -
        <a href="privacy.html" target="_blank">プライバシーポリシー</a> -
        <a href="transaction.html" target="_blank">特定商品取引に関する表示</a>
      </div>
    </div>

  </v-ons-page>
</template>

<style scoped>
.list-item {
  height: initial;
}
</style>

<script>
import loginPage from "./LoginPage";
import paymentPage from "./PaymentPage";
import downloadPage from "./DownloadPage";
import priceConstants from "./PriceConstants.js"
import moment from "moment";

import {
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  onSnapshot,
  where,
  enableIndexedDbPersistence,
} from "firebase/firestore";

var _ = require("lodash");

// 契約状況のマップ
const PRICE_MAP = {};
PRICE_MAP[priceConstants['PRICE_980']] = 5; // 講義 + えんぷり（IA、ⅡB、Ⅲ） + はいぷり
PRICE_MAP[priceConstants['PRICE_680']] = 4; // 講義 + えんぷり（IA、ⅡB、Ⅲ）
PRICE_MAP[priceConstants['PRICE_480']] = 3; // 講義 + えんぷり（IA、ⅡB）
PRICE_MAP[priceConstants['PRICE_280']] = 2; // 講義 + えんぷり（IA）
// FREE: 1
// GUEST: 0

export default {
  created: function () {
   
    // 遅延して実行する関数
    this.delayCalcKougiUnits = _.debounce(this.calcKougiUnits, 50);
    this.delayCalcEnpuriUnits = _.debounce(this.calcEnpuriUnits, 50);
    this.delayCalcHighpuriUnits = _.debounce(this.calcHighpuriUnits, 50);
    this.initMasterMap();

    // Firestoreの変更を監視
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      const db = getFirestore();
      // ログインに成功するとuserに情報が設定される
      if (user) {
        let loginUser = {
          email: user.email,
          userName: user.displayName,
          photoURL: user.photoURL || 'img/common/user.png',
          contractId: 1,
          subscriptionId: '',
          priceId: '',
          start: '',
          end: ''
        };
        // キャッシュの有効化
        enableIndexedDbPersistence(db)
          .catch((err) => {
            console.log(err);
            if (err.code == 'failed-precondition') {
              // ...
            } else if (err.code == 'unimplemented') {
              // ...
            }
          });

        // データの取得（契約情報がある場合）
        const docSnap = await getDoc(doc(db, "users", user.email));
        if (docSnap.exists() && docSnap.data().subscription) {
          loginUser.subscriptionId = docSnap.data().subscription.subscriptionId;
          loginUser.priceId = docSnap.data().subscription.priceId;
          loginUser.start = docSnap.data().subscription.start;
          loginUser.end = docSnap.data().subscription.end;
          loginUser.cancelAtPeriodEnd = docSnap.data().subscription.cancelAtPeriodEnd;
          if (loginUser.end >= moment().format('YYYY-MM-DD HH:mm:ss')) {
            loginUser.contractId = PRICE_MAP[loginUser.priceId];
          }
         }

        // ボリューム契約があるかどうか
        const docsSnap = await getDocs(await query(collectionGroup(db, "members"), where("email", "==", auth.currentUser.email)));
        if (docsSnap.docs.length > 0) {
          if (docsSnap.docs[0].data().contractId) {
            loginUser.contractId = docsSnap.docs[0].data().contractId; 
          } else {
            loginUser.contractId = 9;
          }
        }

        // ユーザ情報の設定
        this.$store.commit("loginUser", loginUser);

        // 学習情報の設定
        const recordMap = this.$store.state.recordMap;
        const records = query(collection(db, "users/" + user.email + "/records"));
        onSnapshot(records, (snapshot) => {
          // let start = new Date();
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              recordMap[change.doc.data().uid] = change.doc.data().value;
            }
            if (change.type === "modified") {
              recordMap[change.doc.data().uid] = change.doc.data().value;
            }
            if (change.type === "removed") {
              // do nothing.
            }
          });
          // console.log(new Date() - start + ["ms"]);
          this.delayCalcKougiUnits();
          this.delayCalcEnpuriUnits();
          this.delayCalcHighpuriUnits();
        });
      } else {
        this.$store.commit("logoutUser");
      }

      this.$root.hideModal();

      this.checkVersion();
    });
  },
  data() {
    return {
      localVersion: '-'
    };
  },
  methods: {

    async checkVersion() {
      console.log('ckeck version.');
      const db = getFirestore();
      const docSnap = await getDoc(doc(db, "settings", "app"));
      if (docSnap.exists()) {
        let currentVersion = docSnap.data().version;
        this.localVersion = this.$cookies.get('enpuri.version');
        if (!this.localVersion) {
          console.log('new version -> ' + currentVersion);
          this.$cookies.set('enpuri.version', currentVersion);
        }
        if (currentVersion != this.localVersion) {
          console.log('update version -> ' + currentVersion);
          this.$cookies.set('enpuri.version', currentVersion);
          window.navigator.serviceWorker.getRegistrations()
            .then(registrations => {
              for(let registration of registrations) {
                registration.unregister();
              }
            });
          window.location.reload(true);
        }
      }
    },

    formatDate(str) {
      return moment(str).format('YYYY-MM-DD HH:mm');
    },

    pushLoginPage() {
      this.$emit("push-page", {
        extends: loginPage,
        data() {
          return {
          };
        },
      });
    },

    pushPaymentPage() {
      this.$emit("push-page", {
        extends: paymentPage,
        data() {
          return {
          };
        },
      });
    },

    pushDownloadPage() {
      this.$emit("push-page", {
        extends: downloadPage,
        data() {
          return {
          };
        },
      });
    },

    mailto() {
      window.open('mailto:support@enpuri.jp?subject=岩ちゃんのえんぷりに関するお問い合わせ', '_blank');
    },

    calcKougiUnits: function () {
      let kougis = this.$store.state.kougis;
      for (let i = 0; i < kougis.length; i++) {
        let unit = kougis[i];
        this.calcKougiUnit(unit);
      }
    },

    calcKougiUnit: function (unit) {
      let rtn = {
        sum: 0,
        1: 0,
        2: 0,
        3: 0,
      };
      for (let i = 0; i < unit.prints.length; i++) {
        let r = this.calcKougiPrint(unit.prints[i]);
        rtn["sum"] += r["sum"];
        rtn["1"] += r["1"];
        rtn["2"] += r["2"];
        rtn["3"] += r["3"];
      }
      unit.record = rtn;
      return rtn;
    },

    calcKougiPrint: function (print) {
      let rtn = {
        sum: 0,
        1: 0,
        2: 0,
        3: 0,
      };
      for (let i = 0; i < print.kougis.length; i++) {
        let r = this.calcKougi(print.kougis[i]);
        rtn["sum"] += r["sum"];
        rtn["1"] += r["1"];
        rtn["2"] += r["2"];
        rtn["3"] += r["3"];
      }
      print.record = rtn;
      return rtn;
    },

    calcKougi: function (kougi) {
      const recordMap = this.$store.state.recordMap;
      let val = recordMap["kogi_" + kougi.kuid];
      let rtn = {
        sum: 1,
        1: 0,
        2: 0,
        3: 0,
      };
      if (val) {
        rtn[val]++;
      }
      kougi.record = rtn;
      return rtn;
    },

    calcEnpuriUnits: function () {
      let enpuris = this.$store.state.enpuris;
      for (let i = 0; i < enpuris.length; i++) {
        let unit = enpuris[i];
        this.calcEnpuriUnit(unit);
      }
    },

    calcEnpuriUnit: function (unit) {
      let rtn = {
        sum: 0,
        1: 0,
        2: 0,
        3: 0,
      };
      for (let i = 0; i < unit.prints.length; i++) {
        let r = this.calcEnpuriPrint(unit.prints[i]);
        rtn["sum"] += r["sum"];
        rtn["1"] += r["1"];
        rtn["2"] += r["2"];
        rtn["3"] += r["3"];
      }
      unit.record = rtn;
      return rtn;
    },

    calcEnpuriPrint: function (print) {
      let rtn = {
        sum: 0,
        1: 0,
        2: 0,
        3: 0,
      };
      for (let i = 0; i < print.questions.length; i++) {
        let r = this.calcEnpuriQuestion(print.questions[i]);
        rtn["sum"] += r["sum"];
        rtn["1"] += r["1"];
        rtn["2"] += r["2"];
        rtn["3"] += r["3"];
      }
      print.record = rtn;
      return rtn;
    },

    calcEnpuriQuestion: function (question) {
      const recordMap = this.$store.state.recordMap;
      let val = recordMap["enpr_" + question.quid];
      let rtn = {
        sum: 1,
        1: 0,
        2: 0,
        3: 0,
      };
      if (val) {
        rtn[val]++;
      }
      question.record = rtn;
      return rtn;
    },

    calcHighpuriUnits: function () {
      let highpuris = this.$store.state.highpuris;
      for (let i = 0; i < highpuris.length; i++) {
        let unit = highpuris[i];
        this.calcHighpuriUnit(unit);
      }
    },

    calcHighpuriUnit: function (unit) {
      let rtn = {
        sum: 0,
        1: 0,
        2: 0,
        3: 0,
      };
      for (let i = 0; i < unit.prints.length; i++) {
        let r = this.calcHighpuriPrint(unit.prints[i]);
        rtn["sum"] += r["sum"];
        rtn["1"] += r["1"];
        rtn["2"] += r["2"];
        rtn["3"] += r["3"];
      }
      unit.record = rtn;
      return rtn;
    },

    calcHighpuriPrint: function (print) {
      let rtn = {
        sum: 0,
        1: 0,
        2: 0,
        3: 0,
      };
      for (let i = 0; i < print.questions.length; i++) {
        let r = this.calcHighpuriQuestion(print.questions[i]);
        rtn["sum"] += r["sum"];
        rtn["1"] += r["1"];
        rtn["2"] += r["2"];
        rtn["3"] += r["3"];
      }
      print.record = rtn;
      return rtn;
    },

    calcHighpuriQuestion: function (question) {
      const recordMap = this.$store.state.recordMap;
      let val = recordMap["high_" + question.quid];
      let rtn = {
        sum: 1,
        1: 0,
        2: 0,
        3: 0,
      };
      if (val) {
        rtn[val]++;
      }
      question.record = rtn;
      return rtn;
    },
    
    initMasterMap: function () {
      const enpuris = this.$store.state.enpuris;
      for (let i = 0; i < enpuris.length; i++) {
        for (let j = 0; j < enpuris[i].prints.length; j++) {
          for (let k = 0; k < enpuris[i].prints[j].questions.length; k++) {
            this.$store.state.enpuriMap["enpr_" + enpuris[i].prints[j].questions[k].quid] =
              enpuris[i].prints[j].questions[k];
          }
        }
      }

      const kougis = this.$store.state.kougis;
      for (let i = 0; i < kougis.length; i++) {
        for (let j = 0; j < kougis[i].prints.length; j++) {
          for (let k = 0; k < kougis[i].prints[j].kougis.length; k++) {
            this.$store.state.kougiMap["kogi_" + kougis[i].prints[j].kougis[k].kuid] =
              kougis[i].prints[j].kougis[k];
          }
        }
      }
    },
  },
};
</script>
