<template>
  <v-ons-navigator
    swipeable
    :page-stack="pageStack"
    @push-page="pushPage"
    @pop-page="popPage"
    @top-page="topPage"
  >
  </v-ons-navigator>
</template>

<script>
import unitsPage from "./UnitsPage";

export default {
  data() {
    return {
      pageStack: [unitsPage],
    };
  },
  methods: {
    pushPage(page) {
      this.pageStack.push(page);
    },
    popPage() {
      this.pageStack.pop();
    },
    topPage() {
      this.pageStack = [this.pageStack[0]];
    }
  },
};
</script>
