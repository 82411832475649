<template>
  <v-ons-page>
    <v-ons-tabbar
      position="auto"
      animation-options="{duration: 0.3, delay: 1, timing: 'ease-in'}"
      :visible="true"
      :tabs="tabs"
      :index.sync="$store.state.tabIndex"
      @postchange="changeTab"
    >
    </v-ons-tabbar>
  </v-ons-page>
</template>

<style>
div.tabbar {
  height: 60px;
}
</style>

<script>
/* eslint-disable vue/no-unused-components */
import KougiNavi from "./kougi/KougiNavi.vue";
import EnpuriNavi from "./enpuri/EnpuriNavi.vue";
import HighpuriNavi from "./highpuri/HighpuriNavi.vue";
import SettingsNavi from "./settings/SettingsNavi.vue";

export default {
  components: { KougiNavi, EnpuriNavi, HighpuriNavi, SettingsNavi },
  data() {
    return {
      tabs: [
        {
          icon: "fa-chalkboard-teacher",
          label: "講義",
          page: KougiNavi,
        },
        {
          icon: "fa-file-alt",
          label: "えんぷり",
          page: EnpuriNavi,
        },
        {
          icon: "fa-file-upload",
          label: "はいぷり",
          page: HighpuriNavi,
        },
        {
          icon: "fa-cog",
          label: "設定",
          page: SettingsNavi,
        }
      ],
      modalVisible: true,
      timeoutID: 0
    };
  },
  created: function () {
    this.$store.state.tabIndex = parseInt(localStorage.getItem("tabIndex") || "0", 10);
  },
  methods: {
    changeTab(event) {
      localStorage.setItem("tabIndex", event.activeIndex);
    },
  },
};
</script>
