<template>
  <v-ons-page>

    <div style="padding: 10px;">ボリュームライセンス</div>

    <v-ons-list>
      <v-ons-list-header>グループ</v-ons-list-header>
      <v-ons-list-item>
        <div class="center">
          <div style="padding: 10px 0px 10px 30px;">
            <v-ons-select v-model="selectedVolume">
              <option v-for="volume in volumes" :value="volume.value" :key="volume.value">
                {{ volume.value }}
              </option>
            </v-ons-select>
          </div>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header>メンバーの追加</v-ons-list-header>
      <v-ons-list-item>
        <div class="center">
          <div style="padding: 10px 0px 10px 30px; width: 100%;">
            <v-ons-row>
              <v-ons-col width="70%">
                <v-ons-input type="email" placeholder="eメール" v-model.trim="email"></v-ons-input>
              </v-ons-col>
              <v-ons-col>
                <v-ons-input type="text" placeholder="名前" v-model.trim="name"></v-ons-input>
              </v-ons-col>
            </v-ons-row>
          </div>
        </div>
        <div class="right">
          <div style="padding: 0px">
            <v-ons-button modifier="cta" @click="addMember()" :disabled="!(email.length > 0 && name.length > 0)">追加</v-ons-button>
          </div>
        </div>
      </v-ons-list-item>
    </v-ons-list>

    <v-ons-list>
      <v-ons-list-header>メンバーの一覧</v-ons-list-header>
      <v-ons-list-item v-for="member in members" :key="member.email">
        <div class="center">
          <div style="padding: 10px 0px 10px 30px; width: 100%;">
            <v-ons-row>
              <v-ons-col width="70%">
                {{ member.email }}
              </v-ons-col>
              <v-ons-col>
                {{ member.name }}
              </v-ons-col>
            </v-ons-row>
          </div>
        </div>
        <div class="right">
          <div style="margin: 0px">
            <v-ons-button modifier="cta" @click="removeMember(member.email)">削除</v-ons-button>
          </div>
        </div>
      </v-ons-list-item>
    </v-ons-list>

  </v-ons-page>
</template>

<script>
import {
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  query,
  collection,
  doc,
  where,
  getDocs,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

export default {
  data() {
    return {
      volumes: [],
      selectedVolume: '',
      members: [],
      email: '',
      name: ''
    };
  },
  created: async function () {
    let that = this;
    this.$root.hideModal();

    const auth = getAuth();
    onAuthStateChanged(auth, async () => {
      if (!auth.currentUser) {
        location.replace('/');
      }
      const db = getFirestore();
      const q = query(collection(db, "volumes"), where("owners", "array-contains-any", [auth.currentUser.email]));
      const querySnapshot = await getDocs(q);
      that.volumes = [];
      querySnapshot.forEach((doc) => {
        that.volumes.push({
          value: doc.id,
          contractId: doc.data().contractId
        })
      });
      if (that.volumes.length > 0) {
        that.selectedVolume = that.volumes[0].value;
      } else {
        location.replace('/');
      }
    });
  },
  methods: {
    loadMembers: async function() {
      const db = getFirestore();
      const q = query(collection(db, "volumes", this.selectedVolume, "members"));
      const querySnapshot = await getDocs(q);
      this.members = [];
      querySnapshot.forEach((doc) => {
        this.members.push({
          email: doc.data().email,
          name: doc.data().name
        })
      });
    },
    addMember: async function() {
      if (window.confirm("メンバーを追加してもよろしいですか？")) {
        const db = getFirestore();
        const volume = this.selectedVolume;
        const docRef = doc(db, "volumes", volume, "members", this.email.trim());
        const data = {
          email: this.email.trim(),
          name: this.name.trim(),
          contractId: this.volumes.find(item => item.value == volume).contractId
        }
        await setDoc(docRef, data);
        this.email = "";
        this.name = "";
        this.loadMembers();
        }
    },
    removeMember: async function(email) {
      if (window.confirm("メンバーを削除してもよろしいですか？")) {
        const db = getFirestore();
        const docRef = doc(db, "volumes", this.selectedVolume, "members", email);
        await deleteDoc(docRef);
        this.loadMembers();
      }
    }
  },
  watch: {
    selectedVolume: async function() {
      this.email = '';
      this.name = '';
      this.loadMembers();
    }
  }
};
</script>
