<template>
  <v-ons-page>
    <v-ons-toolbar style="height: 44px;">
      <div class="left">
        <v-ons-back-button>戻る</v-ons-back-button>
      </div>
      <div class="center">
        <img src="img/kogi/header_logo.png" height="44" />
      </div>
      <div class="right">
        <span class="home" @click="popTopPage"><i class="fa fa-home" aria-hidden="true"></i></span>
      </div>
    </v-ons-toolbar>
    <div>
      <v-ons-list>
        <template v-for="(print, index) in unit.prints">
          <v-ons-list-header
            v-bind:key="'h.' + print.pid"
            v-if="index == 0 || print.hn != unit.prints[index - 1].hn"
            class="l1"
          >
            {{ print.hn }}
          </v-ons-list-header>
          <v-ons-list-item
            v-bind:key="print.pid"
            @click="push(print)"
            tappable
            modifier="chevron"
          >
            <div class="center">
              <img
                :src="'img/kogi/unit/' + unit.uid + '.png'"
                width="50px"
                height="50px"
                style="margin: 0px 20px 0px 20px"
              />
              <div class="page_title">{{ print.pn }}</div>
            </div>
            <div class="right" style="display: block; height: 80px;">
              <div style="text-align: right; margin-top: 9px;">{{ percentage(print) }}</div>
              <div v-if="percentage(print)" style="width: 64px;">
                <span class="notification v1">{{ print.record["1"] }}</span>
                <span class="notification v2">{{ print.record["2"] }}</span>
                <span class="notification v3">{{ print.record["3"] }}</span>
              </div>
            </div>
          </v-ons-list-item>
        </template>
      </v-ons-list>
    </div>
  </v-ons-page>
</template>

<script>
import printPage from "./PrintPage.vue";
import memberPage from "./MemberPage.vue";

export default {
  methods: {
    popTopPage() {
      this.$emit("top-page");
    },
    push(print) {
      if (this.canStudy()) {
        this.$emit("push-page", {
          extends: printPage,
          data() {
            return {
              print: print,
            };
          },
        });
      } else {
        this.$emit("push-page", {
          extends: memberPage,
          data() {
            return {
              print: print,
            };
          },
        });
      }
    },
    percentage(print) {
      if (print.record && print.record["sum"]) {
        return (
          print.record["1"] +
          print.record["2"] +
          print.record["3"] +
          " / " +
          print.record["sum"]
        );
      }
    },
    canStudy() {
      let user = this.$store.state.loginUser;
      switch (user.contractId) {

        case 0: // ゲスト
        case 1: // 無料ユーザー
        case 2: // スタンダード 1
          if (this.unit.fid == "1a") {
            return true;
          } else {
            return false;
          }
        
        case 3: // スタンダード 2
          if (this.unit.fid == "1a" || this.unit.fid == "2b") {
            return true;
          } else {
            return false;
          }
        
        case 4: // スタンダード 3
        case 5: // プレミアム
        case 8: // ボリュームライセンス（えんぷり）
        case 9: // ボリュームライセンス（はいぷり）
          return true;

        default:
          return false;
      }
    }
  }
};
</script>
