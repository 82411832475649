<template>
  <v-ons-page>
    <v-ons-toolbar style="height: 44px">
      <div class="left">
        <v-ons-back-button>戻る</v-ons-back-button>
      </div>
      <div class="center">
        <img src="img/settings/header_logo.png" height="44" />
      </div>
    </v-ons-toolbar>
    <div>
      <v-ons-list>

        <v-ons-list-item>
          <div style="padding: 20px; width: 100%">
            <div class="contract_title">プレミアム</div>
            <div class="contract_desc">
              <div style="margin-bottom: 8px;">講義動画（ⅠA、ⅡBC、Ⅲ）とえんぷり（ⅠA、ⅡBC、Ⅲ）及び、はいぷり、なんぷりの演習問題が学習可能です。</div>
              <div><div style="float: left; width: 180px;">・講義動画（ⅠA、ⅡBC、Ⅲ）</div><div>386 動画</div></div>
              <div><div style="float: left; width: 180px;">・えんぷり（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
              <div><div style="float: left; width: 180px;">・えんぷく（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
              <div><div style="float: left; width: 180px;">・ぷくぷく（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
              <div><div style="float: left; width: 180px;">・はいぷり</div><div>261 問</div></div>
              <div><div style="float: left; width: 180px;">・なんぷり</div><div>33 問</div></div>
            </div>
            <div style="padding: 10px 10px 0px 10px;" v-if="canChangePlan(5)">
              <v-ons-button modifier="large cta" @click="doPayment(priceConstants['PRICE_980'])">
                プランを変更する（980円/月）
              </v-ons-button>
            </div>
            <div style="padding: 10px 10px 0px 10px;" v-if="!canChangePlan(5)">
              <v-ons-button modifier="large cta" disabled="true">
                契約中（980円/月）
              </v-ons-button>
            </div>
          </div>
        </v-ons-list-item>

        <v-ons-list-item>
          <div style="padding: 20px; width: 100%">
            <div class="contract_title">スタンダード（ⅠA、ⅡBC、Ⅲ）</div>
            <div class="contract_desc">
              <div style="margin-bottom: 8px;">講義動画（ⅠA、ⅡBC、Ⅲ）とえんぷり（ⅠA、ⅡBC、Ⅲ）の演習問題が学習可能です。</div>
              <div><div style="float: left; width: 180px;">・講義動画（ⅠA、ⅡBC、Ⅲ）</div><div>386 動画</div></div>
              <div><div style="float: left; width: 180px;">・えんぷり（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
              <div><div style="float: left; width: 180px;">・えんぷく（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
              <div><div style="float: left; width: 180px;">・ぷくぷく（ⅠA、ⅡBC、Ⅲ）</div><div>870 問</div></div>
              <div><div style="float: left; width: 180px;">・はいぷり</div><div>0 問</div></div>
              <div><div style="float: left; width: 180px;">・なんぷり</div><div>0 問</div></div>
            </div>
            <div style="padding: 10px 10px 0px 10px;" v-if="canChangePlan(4)">
              <v-ons-button modifier="large cta" @click="doPayment(priceConstants['PRICE_680'])">
                プランを変更する（680円/月）
              </v-ons-button>
            </div>
            <div style="padding: 10px 10px 0px 10px;" v-if="!canChangePlan(4)">
              <v-ons-button modifier="large cta" disabled="true">
                契約中（680円/月）
              </v-ons-button>
            </div>
          </div>
        </v-ons-list-item>

        <v-ons-list-item>
          <div style="padding: 20px; width: 100%">
            <div class="contract_title">スタンダード（ⅠA、ⅡBC）</div>
            <div class="contract_desc">
              <div style="margin-bottom: 8px;">講義動画（ⅠA、ⅡBC）とえんぷり（ⅠA、ⅡBC）の演習問題が学習可能です。</div>
              <div><div style="float: left; width: 180px;">・講義動画（ⅠA、ⅡBC）</div><div>294 動画</div></div>
              <div><div style="float: left; width: 180px;">・えんぷり（ⅠA、ⅡBC）</div><div>630 問</div></div>
              <div><div style="float: left; width: 180px;">・えんぷく（ⅠA、ⅡBC）</div><div>630 問</div></div>
              <div><div style="float: left; width: 180px;">・ぷくぷく（ⅠA、ⅡBC）</div><div>630 問</div></div>
              <div><div style="float: left; width: 180px;">・はいぷり</div><div>0 問</div></div>
              <div><div style="float: left; width: 180px;">・なんぷり</div><div>0 問</div></div>
            </div>
            <div style="padding: 10px 10px 0px 10px;" v-if="canChangePlan(3)">
              <v-ons-button modifier="large cta" @click="doPayment(priceConstants['PRICE_480'])">
                プランを変更する（480円/月）
              </v-ons-button>
            </div>
            <div style="padding: 10px 10px 0px 10px;" v-if="!canChangePlan(3)">
              <v-ons-button modifier="large cta" disabled="true">
                契約中（480円/月）
              </v-ons-button>
            </div>
          </div>
        </v-ons-list-item>

        <v-ons-list-item>
          <div style="padding: 20px; width: 100%">
            <div class="contract_title">スタンダード（ⅠA）</div>
            <div class="contract_desc">
              <div style="margin-bottom: 8px;">講義動画（ⅠA）とえんぷり（ⅠA）の演習問題が学習可能です。</div>
              <div><div style="float: left; width: 180px;">・講義動画（ⅠA）</div><div>120 動画</div></div>
              <div><div style="float: left; width: 180px;">・えんぷり（ⅠA）</div><div>262 問</div></div>
              <div><div style="float: left; width: 180px;">・えんぷく（ⅠA）</div><div>262 問</div></div>
              <div><div style="float: left; width: 180px;">・ぷくぷく（ⅠA）</div><div>262 問</div></div>
              <div><div style="float: left; width: 180px;">・はいぷり</div><div>0 問</div></div>
              <div><div style="float: left; width: 180px;">・なんぷり</div><div>0 問</div></div>
            </div>
            <div style="padding: 10px 10px 0px 10px;" v-if="canChangePlan(2)">
              <v-ons-button modifier="large cta" @click="doPayment(priceConstants['PRICE_280'])">
                プランを変更する（280円/月）
              </v-ons-button>
            </div>
            <div style="padding: 10px 10px 0px 10px;" v-if="!canChangePlan(2)">
              <v-ons-button modifier="large cta" disabled="true">
                契約中（280円/月）
              </v-ons-button>
            </div>
          </div>
        </v-ons-list-item>

        <v-ons-list-item>
          <div style="padding: 20px; width: 100%">
            <div class="contract_title">無料ユーザー</div>
            <div class="contract_desc">
              <div style="margin-bottom: 8px;">講義動画（ⅠA）とえんぷり（ⅠA）の「数と式・集合と論理」の演習問題が学習可能です。</div>
              <div><div style="float: left; width: 180px;">・講義動画（ⅠA）</div><div>120 動画</div></div>
              <div><div style="float: left; width: 180px;">・えんぷり（ⅠAの一部）</div><div>60 問</div></div>
              <div><div style="float: left; width: 180px;">・えんぷく（ⅠAの一部）</div><div>60 問</div></div>
              <div><div style="float: left; width: 180px;">・ぷくぷく（ⅠAの一部）</div><div>60 問</div></div>
              <div><div style="float: left; width: 180px;">・はいぷり</div><div>0 問</div></div>
              <div><div style="float: left; width: 180px;">・なんぷり</div><div>0 問</div></div>
            </div>
            <div style="padding: 10px 10px 0px 10px;" v-if="canChangePlan(1)">
              <v-ons-button modifier="large cta" @click="doCancel()">
                プランを変更する（0円/月）
              </v-ons-button>
            </div>
            <div style="padding: 10px 10px 0px 10px;" v-if="!canChangePlan(1)">
              <v-ons-button modifier="large cta" disabled="true">
                契約中（0円/月）
              </v-ons-button>
            </div>
          </div>
        </v-ons-list-item>

        <v-ons-list-item>
          <div style="padding: 20px 20px 30px 20px;">
            <div class="contract_desc">
              ※ プランを変更しても支払日は変わりません。<br />
              ※ 高価格のプランに変更した場合、増額分は、日割りで計算され、次回の支払日にまとめて請求されます。<br />
              ※ 低価格のプランに変更しても返金には応じられませんが、差額はチャージされ、次回の支払いに使用されます。<br />
            </div>
          </div>
        </v-ons-list-item>  
      </v-ons-list>
    </div>
  </v-ons-page>
</template>

<style scoped>
.list-item {
  height: initial;
}
</style>

<script>
import priceConstants from "./PriceConstants.js"
import moment from "moment";

const CREATE_SUBSCRIPTION_URL = 'https://us-central1-enpuri-2021.cloudfunctions.net/createCheckoutSession';
const UPDATE_SUBSCRIPTION_URL = 'https://us-central1-enpuri-2021.cloudfunctions.net/updateCheckoutSession';
const EXPIRE_SUBSCRIPTION_URL = 'https://us-central1-enpuri-2021.cloudfunctions.net/expireCheckoutSession';

export default {
  data() {
    return {
      priceConstants: priceConstants
    };
  },
  methods: {
    formatDate(str) {
      return moment(str).format('YYYY-MM-DD HH:mm');
    },
    popTopPage() {
      this.$emit("top-page");
    },
    canChangePlan(contractId) {
      if (this.$store.state.loginUser.cancelAtPeriodEnd) {
        if (contractId == 1) {
          return false;
        } else {
          return true;
        }
      } else {
        return this.$store.state.loginUser.contractId != contractId;
      }
    },
    doPayment(priceId) {
      let user = this.$store.state.loginUser;
      if (user.subscriptionId) {
        this.updateSubscription(user.email, user.subscriptionId, priceId);
      } else {
        this.createSubscription(user.email, priceId);
      }
    },
    doCancel() {
      let user = this.$store.state.loginUser;
      this.expireSubscription(user.email, user.subscriptionId);
    },
    createSubscription: function(email, priceId) {
      this.$root.showModal();
      var form = document.createElement('form');
      form.method = 'POST';
      form.action = CREATE_SUBSCRIPTION_URL;
      var emailInput = document.createElement('input');
      emailInput.type = 'hidden';
      emailInput.name = 'customer_email';
      emailInput.value = email;
      form.appendChild(emailInput);
      var priceInput = document.createElement('input');
      priceInput.type = 'hidden';
      priceInput.name = 'price_id';
      priceInput.value = priceId;
      form.appendChild(priceInput);
      document.body.appendChild(form);
      form.submit();
    },
    updateSubscription: function(email, subscriptionId, priceId) {
      this.$root.showModal();
      var form = document.createElement('form');
      form.method = 'POST';
      form.action = UPDATE_SUBSCRIPTION_URL;
      var emailInput = document.createElement('input');
      emailInput.type = 'hidden';
      emailInput.name = 'customer_email';
      emailInput.value = email;
      form.appendChild(emailInput);
      var subscriptionInput = document.createElement('input');
      subscriptionInput.type = 'hidden';
      subscriptionInput.name = 'subscription_id';
      subscriptionInput.value = subscriptionId;
      form.appendChild(subscriptionInput);
      var priceInput = document.createElement('input');
      priceInput.type = 'hidden';
      priceInput.name = 'price_id';
      priceInput.value = priceId;
      form.appendChild(priceInput);
      document.body.appendChild(form);
      form.submit();
    },
    expireSubscription: function(email, subscriptionId) {
      this.$root.showModal();
      var form = document.createElement('form');
      form.method = 'POST';
      form.action = EXPIRE_SUBSCRIPTION_URL;
      var emailInput = document.createElement('input');
      emailInput.type = 'hidden';
      emailInput.name = 'customer_email';
      emailInput.value = email;
      form.appendChild(emailInput);
      var subscriptionInput = document.createElement('input');
      subscriptionInput.type = 'hidden';
      subscriptionInput.name = 'subscription_id';
      subscriptionInput.value = subscriptionId;
      form.appendChild(subscriptionInput);
      document.body.appendChild(form);
      form.submit();
    },
  },
};
</script>
