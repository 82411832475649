<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button>戻る</v-ons-back-button>
      </div>
      <div class="center">
        <img src="img/kogi/header_logo.png" height="44" />
      </div>
      <div class="right">
        <span class="home" @click="popTopPage"><i class="fa fa-home" aria-hidden="true"></i></span>
      </div>
    </v-ons-toolbar>
    <div>
      <v-ons-list>
        <v-ons-list-header class="l1">
          {{ kougi.hn }}
        </v-ons-list-header>
        <v-ons-list-header class="l2">
          {{ kougi.kn }}
        </v-ons-list-header>
      </v-ons-list>
      <iframe
        frameborder="0"
        :src="'https://www.videog.jp/player/' + kougi.vid"
        scrolling="no"
        width="100%"
        height="400px"
        allowfullscreen
      ></iframe>
      <div style="text-align: center; margin-top: 10px" v-if="$store.state.loginUser.email">
        <v-ons-button
          class="button-v1"
          style="margin: 6px"
          :modifier="getModifier('1')"
          @click="setRecord(kougi, '1')"
          >完璧</v-ons-button
        >
        <v-ons-button
          class="button-v2"
          style="margin: 6px"
          :modifier="getModifier('2')"
          @click="setRecord(kougi, '2')"
          >理解</v-ons-button
        >
        <v-ons-button
          class="button-v3"
          style="margin: 6px"
          :modifier="getModifier('3')"
          @click="setRecord(kougi, '3')"
          >復習</v-ons-button
        >
      </div>
    </div>
  </v-ons-page>
</template>

<script>
import { getFirestore, doc, setDoc, serverTimestamp } from "firebase/firestore";

export default {
  data() {
    return {
      value: 0,
    };
  },
  created: function () {
    this.value = this.$store.state.recordMap["kogi_" + this.kougi.kuid];
  },
  methods: {
    popTopPage() {
      this.$emit("top-page");
    },
    setRecord: async function (kougi, value) {
      let user = this.$store.state.loginUser;
      if (user) {
        this.value = value;
        try {
          const db = getFirestore();
          await setDoc(
            doc(db, "users/" + user.email + "/records", "kogi_" + kougi.kuid),
            {
              uid: "kogi_" + kougi.kuid,
              value: value,
              timestamp: serverTimestamp(),
            }
          );
          this.$emit("pop-page");
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
    },
    getModifier(value) {
      if (this.value == value) {
        return "";
      } else {
        return "outline";
      }
    },
  },
};
</script>
