var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-ons-page',[_c('v-ons-toolbar',{staticStyle:{"height":"44px"}},[_c('div',{staticClass:"left"},[_c('v-ons-back-button',[_vm._v("戻る")])],1),_c('div',{staticClass:"center"},[_c('img',{attrs:{"src":"img/enpr/header_logo.png","height":"44"}})]),_c('div',{staticClass:"right"},[_c('span',{staticClass:"home",on:{"click":_vm.popTopPage}},[_c('i',{staticClass:"fa fa-home",attrs:{"aria-hidden":"true"}})])])]),_c('div',[_c('v-ons-list',[_c('v-ons-list-header',{staticClass:"l1"},[_vm._v(" "+_vm._s(_vm.question.hn)+" ")]),_c('v-ons-list-header',{staticClass:"l2"},[_c('div',{staticClass:"question-header"},[_c('span',{staticClass:"question-num"},[_vm._v(_vm._s(_vm.question.qnum1))]),(_vm.question.qnum2 == 0 || _vm.question.qnum2 == 98 || _vm.question.qnum2 == 99)?_c('span',[_vm._v("  ")]):_c('span',[_vm._v("（"+_vm._s(_vm.question.qnum2)+"）")]),_vm._v(" "+_vm._s(_vm.question.qn)+" ")])]),_c('v-ons-list-item',[_c('v-ons-toolbar',[_c('div',{staticClass:"center",staticStyle:{"line-height":"42px"}},[_c('v-ons-segment',{staticStyle:{"width":"280px"},attrs:{"index":_vm.segmentIndex},on:{"update:index":function($event){_vm.segmentIndex=$event}}},[_c('button',[_vm._v("問題")]),_c('button',[_vm._v("解答")])])],1)])],1),_c('v-ons-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.segmentIndex == 0),expression:"segmentIndex == 0"}]},[_c('div',{staticStyle:{"padding":"20px"}},[_c('img',{staticClass:"question-image",attrs:{"src":'img/pkpk/' +
              _vm.question.uid +
              '/ques/' +
              _vm.question.quid +
              '.jpg?_t=201803080001'}}),_c('p',{staticStyle:{"text-align":"center","width":"100%"}},[(_vm.question.qnum2 == 98)?_c('img',{staticStyle:{"width":"55%"},attrs:{"src":"img/98.png?_t=201803080001"}}):_vm._e(),(_vm.question.qnum2 == 99)?_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"img/99.png?_t=201803080001"}}):_vm._e()])])]),_c('v-ons-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.segmentIndex == 1),expression:"segmentIndex == 1"}]},[_c('div',{staticStyle:{"padding":"20px"}},[_c('img',{staticClass:"question-image",attrs:{"src":'img/pkpk/' +
              _vm.question.uid +
              '/answ/' +
              _vm.question.quid +
              '.jpg?_t=201803080001'}})])]),(_vm.$store.state.loginUser.email)?_c('v-ons-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.segmentIndex == 1),expression:"segmentIndex == 1"}]},[_c('div',{staticStyle:{"text-align":"center","margin":"10px","width":"100%"}},[_c('v-ons-button',{staticClass:"button",staticStyle:{"margin":"6px"},attrs:{"modifier":_vm.getModifier('1')},on:{"click":function($event){return _vm.setRecord(_vm.question, '1')}}},[_vm._v("正解した")]),_c('v-ons-button',{staticClass:"button",staticStyle:{"margin":"6px"},attrs:{"modifier":_vm.getModifier('2')},on:{"click":function($event){return _vm.setRecord(_vm.question, '2')}}},[_vm._v("理解した")]),_c('v-ons-button',{staticClass:"button",staticStyle:{"margin":"6px"},attrs:{"modifier":_vm.getModifier('3')},on:{"click":function($event){return _vm.setRecord(_vm.question, '3')}}},[_vm._v("復習する")])],1)]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }