<template>
  <v-ons-page id="enpuri">
    <v-ons-toolbar style="height: 88px">
      <div class="center">
        <div style="height: 44px;">
          <img src="img/enpr/header_logo.png" height="44" />
        </div>
        <div style="height: 44px;">
          <v-ons-segment :index.sync="segment" style="width: 280px">
            <button>I A</button>
            <button>Ⅱ B C</button>
            <button>Ⅲ</button>
            <button>すべて</button>
          </v-ons-segment>
        </div>
      </div>
    </v-ons-toolbar>

    <v-ons-list>
      <template v-for="(unit, index) in units">
        <v-ons-list-header
          v-bind:key="'h.' + unit.uid"
          v-if="index == 0 || unit.hn != units[index - 1].hn"
          class="l1"
        >
          {{ unit.hn }}
        </v-ons-list-header>
        <v-ons-list-item
          v-bind:key="unit.uid"
          @click="push(unit)"
          tappable
          modifier="chevron"
        >
          <div class="center">
            <img
              :src="'img/enpr/unit/' + unit.uid + '.png'"
              width="50px"
              height="50px"
              style="margin: 0px 20px 0px 20px"
            />
            <div class="page_title">{{ unit.un }}</div>
          </div>
          <div class="right" style="display: block; height: 80px;">
            <div style="text-align: right; padding-top: 9px;">{{ percentage(unit) }}</div>
            <div v-if="percentage(unit)" style="width: 64px;">
              <span class="notification v1">{{ unit.record["1"] }}</span>
              <span class="notification v2">{{ unit.record["2"] }}</span>
              <span class="notification v3">{{ unit.record["3"] }}</span>
            </div>
          </div>
        </v-ons-list-item>
      </template>
    </v-ons-list>
  </v-ons-page>
</template>

<style>
#enpuri .toolbar+.page__background+.page__content {
  top: 88px!important;
}
</style>

<script>
import unitPage from "./UnitPage.vue";

export default {
  data() {
    return {
      segment: 0,
      allUnits: this.$store.state.enpuris,
      units: []
    };
  },
  created: function () {
    this.segment = parseInt(localStorage.getItem("enpuri.segment") || "3", 10);
    this.filter();
  },
  methods: {
    filter: function () {
      let segment = this.segment;
      this.units = this.allUnits.filter(function (unit) {
        return (
          (segment == 0 && unit.fid == "1a") ||
          (segment == 1 && unit.fid == "2bc") ||
          (segment == 2 && unit.fid == "3") ||
          segment == 3
        );
      });
    },
    push(unit) {
      this.$emit("push-page", {
        extends: unitPage,
        data() {
          return {
            unit: unit,
          };
        },
      });
    },
    percentage(unit) {
      if (unit.record && unit.record["sum"]) {
        return (
          unit.record["1"] +
          unit.record["2"] +
          unit.record["3"] +
          " / " +
          unit.record["sum"]
        );
      }
    },
  },
  watch: {
    segment: function (val) {
      this.filter();
      localStorage.setItem("enpuri.segment", val);
    },
  },
};
</script>
