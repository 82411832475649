<template>
  <div id="app">
    <router-view />
    <v-ons-modal :visible="modalVisible">
      <p style="text-align: center">
        更新中 <v-ons-icon icon="fa-spinner" spin></v-ons-icon>
      </p>
    </v-ons-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalVisible: false,
      timeoutID: 0
    };
  },
  created: function () {
    this.$root.showModal();
  },
  methods: {
    showModal() {
      this.modalVisible = true;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => this.modalVisible = false, 10000);
    },
    hideModal() {
      this.modalVisible = false;
    }
  },
};
</script>
