<template>
  <v-ons-navigator
    swipeable
    :page-stack="pageStack"
    @push-page="pushPage"
    @pop-page="popPage"
  >
  </v-ons-navigator>
</template>

<script>
import settingsPage from "./SettingsPage";

export default {
  data() {
    return {
      pageStack: [settingsPage],
    };
  },
  methods: {
    pushPage(page) {
      this.pageStack.push(page);
    },
    popPage() {
      this.pageStack.pop();
    },
  },
};
</script>
