<template>
  <v-ons-page>
    <v-ons-toolbar style="height: 44px">
      <div class="left">
        <v-ons-back-button>戻る</v-ons-back-button>
      </div>
      <div class="center">
        <img src="img/settings/header_logo.png" height="44" />
      </div>
    </v-ons-toolbar>
    <div>
      <v-ons-list>
        <v-ons-list-header class="l1">ログイン情報</v-ons-list-header>
        <v-ons-list-item>
          <div class="left">
            <img class="list-item__thumbnail" style="margin-left: 14px!important;" :src="$store.state.loginUser.photoURL">
          </div>
          <div class="center">
            <span class="list-item__title">{{ $store.state.loginUser.userName }}</span>
            <span class="list-item__subtitle">{{ $store.state.loginUser.email }}</span>
          </div>
        </v-ons-list-item>
      </v-ons-list>
      <div style="padding-top: 10px;">
        <div style="padding: 10px;" v-if="!$store.state.loginUser.email">
          <v-ons-button modifier="large" style="background-color: rgb(219, 68, 55)" @click="signInByGoogle">
            <i class="zmdi zmdi-google zmdi-hc-lg"></i> Googleログイン
          </v-ons-button>
        </div>
        <div style="padding: 10px;" v-if="!$store.state.loginUser.email">
          <v-ons-button modifier="large" style="background-color: rgb(29, 161, 242);" @click="signInByTwitter">
            <span style="font-size: 24px">𝕏</span> (旧Twitter）ログイン
          </v-ons-button>
        </div>
        <div style="padding: 10px;" v-if="$store.state.loginUser.email">
          <v-ons-button modifier="large cta" @click="signOut">
            <i class="zmdi zmdi-power zmdi-hc-lg"></i> ログアウト
          </v-ons-button>
        </div>
      </div>
    </div>
  </v-ons-page>
</template>

<script>

import {
  GoogleAuthProvider,
  TwitterAuthProvider,
  getAuth,
  signInWithRedirect,
  signOut,
} from "firebase/auth";

export default {
  methods: {

    signInByGoogle: function () {
      this.$root.showModal();
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      signInWithRedirect(auth, provider)
        .then((result) => {
          const user = result.user;
          console.log(user);
          location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    signInByTwitter: function () {
      this.$root.showModal();
      const provider = new TwitterAuthProvider();
      const auth = getAuth();
      signInWithRedirect(auth, provider)
        .then((result) => {
          const user = result.user;
          console.log(user);
          location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    signOut: function() {
      this.$root.showModal();
      const auth = getAuth();
      signOut(auth)
      .then(() => {
        location.reload();
      }).catch((error) => {
        console.log(error);
      });
    },
  },
};
</script>
