<template>
  <v-ons-page>
    <v-ons-toolbar style="height: 44px">
      <div class="left">
        <v-ons-back-button>戻る</v-ons-back-button>
      </div>
      <div class="center">
        <img src="img/enpr/header_logo.png" height="44" />
      </div>
      <div class="right">
        <span class="home" @click="popTopPage"><i class="fa fa-home" aria-hidden="true"></i></span>
      </div>
    </v-ons-toolbar>
    <div>
      <v-ons-list>
        <v-ons-list-header class="l1">
          {{ question.hn }}
        </v-ons-list-header>
        <v-ons-list-header class="l2">
          <div class="question-header">
            <span class="question-num">{{ question.qnum1 }}</span>
            <span v-if="question.qnum2 == 0 || question.qnum2 == 98 || question.qnum2 == 99">&nbsp;&nbsp;</span>
            <span v-else>（{{ question.qnum2 }}）</span>
            {{ question.qn }}
          </div>
        </v-ons-list-header>
        <!-- セグメント -->
        <v-ons-list-item>
          <v-ons-toolbar>
            <div class="center" style="line-height: 42px">
              <v-ons-segment :index.sync="segmentIndex" style="width: 280px">
                <button>問題</button>
                <button>解答</button>
                <button>解説</button>
                <button>関連</button>
              </v-ons-segment>
            </div>
          </v-ons-toolbar>
        </v-ons-list-item>

        <!-- 問題 -->
        <v-ons-list-item v-show="segmentIndex == 0">
          <img
            class="question-image"
            :src="
              'img/enpr/' +
              question.uid +
              '/ques/' +
              question.quid +
              '.jpg?_t=201803080001'
            "
          />

          <p style="text-align: center; width: 100%">
            <img
              src="img/98.png?_t=201803080001"
              style="width: 55%"
              v-if="question.qnum2 == 98"
            />
            <img
              src="img/99.png?_t=201803080001"
              style="width: 100%"
              v-if="question.qnum2 == 99"
              />
          </p>

        </v-ons-list-item>

        <!-- 解答 -->
        <v-ons-list-item v-show="segmentIndex == 1">
          <img
            class="question-image"
            :src="
              'img/enpr/' +
              question.uid +
              '/answ/' +
              question.quid +
              '.jpg?_t=201803080001'
            "
          />
        </v-ons-list-item>
        <v-ons-list-item v-show="segmentIndex == 1" v-if="$store.state.loginUser.email">
          <div style="text-align: center; margin: 10px; width: 100%">
            <v-ons-button
              class="button"
              style="margin: 6px"
              :modifier="getModifier('1')"
              @click="setRecord(question, '1')"
              >正解した</v-ons-button
            >
            <v-ons-button
              class="button"
              style="margin: 6px"
              :modifier="getModifier('2')"
              @click="setRecord(question, '2')"
              >理解した</v-ons-button
            >
            <v-ons-button
              class="button"
              style="margin: 6px"
              :modifier="getModifier('3')"
              @click="setRecord(question, '3')"
              >復習する</v-ons-button
            >
          </div>
        </v-ons-list-item>

        <!-- 解説 -->
        <v-ons-list-item v-show="segmentIndex == 2">
          <iframe
            frameborder="0"
            :src="'https://www.videog.jp/player/' + question.vid"
            scrolling="no"
            width="100%"
            height="400px"
            allowfullscreen
          ></iframe>
        </v-ons-list-item>
        <v-ons-list-item v-show="segmentIndex == 2" v-if="$store.state.loginUser.email">
          <div style="text-align: center; margin: 10px; width: 100%">
            <v-ons-button
              class="button"
              style="margin: 6px"
              :modifier="getModifier('1')"
              @click="setRecord(question, '1')"
              >正解した</v-ons-button
            >
            <v-ons-button
              class="button"
              style="margin: 6px"
              :modifier="getModifier('2')"
              @click="setRecord(question, '2')"
              >理解した</v-ons-button
            >
            <v-ons-button
              class="button"
              style="margin: 6px"
              :modifier="getModifier('3')"
              @click="setRecord(question, '3')"
              >復習する</v-ons-button
            >
          </div>
        </v-ons-list-item>

        <!-- 関連 -->
        <div v-show="segmentIndex == 3">

          <!-- 関連講義 -->
          <template v-if="kougis.length > 0">
            <v-ons-list-header class="l3">
              関連講義
            </v-ons-list-header>
            <template  v-for="(kougi, index) in kougis">
              <v-ons-list-header
                v-bind:key="'h.' + kougi.kid"
                v-if="index == 0 || kougi.hn != kougis[index - 1].hn"
                class="l1"
              >
                {{ kougi.hn }}
              </v-ons-list-header>
              <v-ons-list-item
                v-bind:key="kougi.kid"
                @click="pushKougi(kougi)"
                tappable
                modifier="chevron"
              >
                <div class="center">
                  <img
                    :src="'img/kogi/' + kougi.uid + '/' + kougi.kuid + '.jpg'"
                    height="80px"
                    width="142px"
                    style="margin: 0px 20px 0px 0px"
                  />
                  <div class="page_title2">{{ kougi.kn }}</div>
                </div>
                <div class="right" style="width: 82px">
                  <div style="width: 78px; margin-left: 4px">
                    <span class="notification v1 bg" v-if="kougi.record['1']"
                      >完璧</span
                    >
                    <span class="notification v2 bg" v-if="kougi.record['2']"
                      >理解</span
                    >
                    <span class="notification v3 bg" v-if="kougi.record['3']"
                      >復習</span
                    >
                  </div>
                </div>
              </v-ons-list-item>
            </template>
          </template>

          <!-- えんぷく -->
          <template v-if="kougis.length > 0">
            <v-ons-list-header class="l3">
              えんぷく＆ぷくぷく
            </v-ons-list-header>
            <v-ons-list-item
              @click="pushEnpuku(question)"
              tappable
              modifier="chevron"
              style="height: 80px"
            >
              <div class="center">
                <img
                  src="img/common/enpk.jpg"
                  height="50px"
                  style="margin: 0px 20px 0px 20px"
                />
                <div>えんぷく（類題1）</div>
              </div>
              <div class="right" style="width: 82px">
                <div style="width: 78px; margin-left: 4px">
                  <span class="notification v1 bg" v-if="enpk == '1'"
                    >完璧</span
                  >
                  <span class="notification v2 bg" v-if="enpk == '2'"
                    >理解</span
                  >
                  <span class="notification v3 bg" v-if="enpk == '3'"
                    >復習</span
                  >
                </div>
              </div>
            </v-ons-list-item>
            <v-ons-list-item
              @click="pushPukupuku(question)"
              tappable
              modifier="chevron"
              style="height: 80px"
            >
              <div class="center">
                <img
                  src="img/common/pkpk.jpg"
                  height="50px"
                  style="margin: 0px 20px 0px 20px"
                />
                <div>ぷくぷく（類題2）</div>
              </div>
              <div class="right" style="width: 82px">
                <div style="width: 78px; margin-left: 4px">
                  <span class="notification v1 bg" v-if="pkpk == '1'"
                    >完璧</span
                  >
                  <span class="notification v2 bg" v-if="pkpk == '2'"
                    >理解</span
                  >
                  <span class="notification v3 bg" v-if="pkpk == '3'"
                    >復習</span
                  >
                </div>
              </div>
            </v-ons-list-item>
          </template>
        </div>
      </v-ons-list>
    </div>
  </v-ons-page>
</template>
<style scoped>
.list-item {
  height: initial;
}
.question-num {
  border: 1px solid black;
  padding: 1px 5px 1px 5px;
}
.question-image {
  width: 100%;
}
</style>
<script>
import kougiPage from "../kougi/KougiPage.vue";
import enpukuPage from "./EnpukuPage.vue";
import pukupukuPage from "./PukupukuPage.vue";
import { getFirestore, doc, setDoc, serverTimestamp } from "firebase/firestore";

export default {
  data() {
    return {
      value: 0,
      enpk: 0,
      pkpk: 0,
      segmentIndex: 0,
      kougis: [],
    };
  },
  created: function () {
    this.value = this.$store.state.recordMap["enpr_" + this.question.quid];
    for (let i = 0; i < this.question.kuids.length; i++) {
      this.kougis.push(this.$store.state.kougiMap["kogi_" + this.question.kuids[i]]);
    }
  },
  mounted: function() {
    this.enpk = this.$store.state.recordMap["enpk_" + this.question.quid];
    this.pkpk = this.$store.state.recordMap["pkpk_" + this.question.quid];
  },
  methods: {
    popTopPage() {
      this.$emit("top-page");
    },
    pushKougi(kougi) {
      this.$emit("push-page", {
        extends: kougiPage,
        data() {
          return {
            kougi: kougi,
          };
        },
      });
    },
    pushEnpuku(question) {
      let that = this;
      this.$emit("push-page", {
        extends: enpukuPage,
        onsNavigatorProps: {
          passDataBack(data) {
            that.enpk = data;
          }
        },
        data() {
          return {
            question: question,
          };
        },
      });
    },
    pushPukupuku(question) {
      let that = this;
      this.$emit("push-page", {
        extends: pukupukuPage,
        onsNavigatorProps: {
          passDataBack(data) {
            that.pkpk = data;
          }
        },
        data() {
          return {
            question: question,
          };
        },
      });
    },
    setRecord: async function (question, value) {
      let user = this.$store.state.loginUser;
      if (user) {
        this.value = value;
        try {
          const db = getFirestore();
          await setDoc(
            doc(db, "users/" + user.email + "/records", "enpr_" + question.quid),
            {
              uid: "enpr_" + question.quid,
              value: value,
              timestamp: serverTimestamp(),
            }
          );
          this.$emit("pop-page");
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
    },
    getModifier(value) {
      if (this.value == value) {
        return "";
      } else {
        return "outline";
      }
    },
  },
};
</script>
