<template>
  <v-ons-page>
    <v-ons-toolbar style="height: 44px">
      <div class="left">
        <v-ons-back-button>戻る</v-ons-back-button>
      </div>
      <div class="center">
        <img src="img/enpr/header_logo.png" height="44" />
      </div>
      <div class="right">
        <span class="home" @click="popTopPage"><i class="fa fa-home" aria-hidden="true"></i></span>
      </div>
    </v-ons-toolbar>
    <div>
      <v-ons-list>
        <v-ons-list-header class="l1">
          {{ question.hn }}
        </v-ons-list-header>
        <v-ons-list-header class="l2">
          <div class="question-header">
            <span class="question-num">{{ question.qnum1 }}</span>
            <span v-if="question.qnum2 == 0 || question.qnum2 == 98 || question.qnum2 == 99">&nbsp;&nbsp;</span>
            <span v-else>（{{ question.qnum2 }}）</span>
            {{ question.qn }}
          </div>
        </v-ons-list-header>
        <!-- セグメント -->
        <v-ons-list-item>
          <v-ons-toolbar>
            <div class="center" style="line-height: 42px">
              <v-ons-segment :index.sync="segmentIndex" style="width: 280px">
                <button>問題</button>
                <button>解答</button>
              </v-ons-segment>
            </div>
          </v-ons-toolbar>
        </v-ons-list-item>

        <!-- 問題 -->
        <v-ons-list-item v-show="segmentIndex == 0">
          <div style="padding: 20px">
            <img
              class="question-image"
              :src="
                'img/pkpk/' +
                question.uid +
                '/ques/' +
                question.quid +
                '.jpg?_t=201803080001'
              "
            />

            <p style="text-align: center; width: 100%">
              <img
                src="img/98.png?_t=201803080001"
                style="width: 55%"
                v-if="question.qnum2 == 98"
              />
              <img
                src="img/99.png?_t=201803080001"
                style="width: 100%"
                v-if="question.qnum2 == 99"
              />
            </p>            
          </div>
        </v-ons-list-item>

        <!-- 解答 -->
        <v-ons-list-item v-show="segmentIndex == 1">
          <div style="padding: 20px">
            <img
              class="question-image"
              :src="
                'img/pkpk/' +
                question.uid +
                '/answ/' +
                question.quid +
                '.jpg?_t=201803080001'
              "
            />
          </div>
        </v-ons-list-item>
        <v-ons-list-item v-show="segmentIndex == 1" v-if="$store.state.loginUser.email">
          <div style="text-align: center; margin: 10px; width: 100%">
            <v-ons-button
              class="button"
              style="margin: 6px"
              :modifier="getModifier('1')"
              @click="setRecord(question, '1')"
              >正解した</v-ons-button
            >
            <v-ons-button
              class="button"
              style="margin: 6px"
              :modifier="getModifier('2')"
              @click="setRecord(question, '2')"
              >理解した</v-ons-button
            >
            <v-ons-button
              class="button"
              style="margin: 6px"
              :modifier="getModifier('3')"
              @click="setRecord(question, '3')"
              >復習する</v-ons-button
            >
          </div>
        </v-ons-list-item>
       
      </v-ons-list>
    </div>
  </v-ons-page>
</template>
<style scoped>
.list-item {
  height: initial;
}
.question-num {
  border: 1px solid black;
  padding: 1px 5px 1px 5px;
}
.question-image {
  width: 100%;
}
</style>
<script>
import { getFirestore, doc, setDoc, serverTimestamp } from "firebase/firestore";

export default {
  props: ['passDataBack'],
  data() {
    return {
      value: 0,
      segmentIndex: 0,
    };
  },
  created: function () {
    this.value = this.$store.state.recordMap["pkpk_" + this.question.quid];
  },
  methods: {
    popTopPage() {
      this.$emit("top-page");
    },
    setRecord: async function (question, value) {
      let user = this.$store.state.loginUser;
      if (user) {
        this.value = value;
        try {
          const db = getFirestore();
          await setDoc(
            doc(db, "users/" + user.email + "/records", "pkpk_" + this.question.quid),
            {
              uid: "pkpk_" + this.question.quid,
              value: value,
              timestamp: serverTimestamp(),
            }
          );
          this.passDataBack(value);
          this.$emit("pop-page");
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
    },
    getModifier(value) {
      if (this.value == value) {
        return "";
      } else {
        return "outline";
      }
    },
  },
};
</script>
