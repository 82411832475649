<template>
  <v-ons-page>
    <v-ons-toolbar style="height: 44px">
      <div class="left">
        <v-ons-back-button>戻る</v-ons-back-button>
      </div>
      <div class="center">
        <img src="img/high/header_logo.png" height="44" />
      </div>
      <div class="right">
        <span class="home" @click="popTopPage"><i class="fa fa-home" aria-hidden="true"></i></span>
      </div>
    </v-ons-toolbar>
    <div>
      <v-ons-list>
        <template v-for="(question, index) in print.questions">
          <v-ons-list-header
            v-bind:key="'h.' + question.qid"
            v-if="index == 0 || question.hn != print.questions[index - 1].hn"
            class="l1"
          >
            {{ question.hn }}
          </v-ons-list-header>
          <v-ons-list-header v-bind:key="'q.' + question.qid" class="l2">
            <div class="question-header">第 {{ question.qid }} 問</div>
          </v-ons-list-header>
          <v-ons-list-item
            v-bind:key="question.qid"
            @click="push(question)"
            tappable
            modifier="chevron"
          >
            <div class="center">
              <img
                class="question-image"
                :src="
                  'img/high/' +
                  question.uid +
                  '/' +
                  question.quid +
                  '.jpg?_t=201803080001'
                "
              />
            </div>
            <div class="right" style="width: 82px">
              <div style="width: 78px; margin-left: 4px">
                <span class="notification v1 bg" v-if="question.record['1']"
                  >完璧</span
                >
                <span class="notification v2 bg" v-if="question.record['2']"
                  >理解</span
                >
                <span class="notification v3 bg" v-if="question.record['3']"
                  >復習</span
                >
              </div>
            </div>
          </v-ons-list-item>
        </template>
      </v-ons-list>
    </div>
  </v-ons-page>
</template>
<style scoped>
.list-item {
  height: initial;
}
.question-header {
  padding: 2px 0px 2px 0px;
}
.question-num {
  border: 1px solid black;
  padding: 1px 5px 1px 5px;
}
.question-image {
  width: 100%;
}
</style>
<script>
import questionPage from "./QuestionPage.vue";

export default {
  methods: {
    popTopPage() {
      this.$emit("top-page");
    },
    push(question) {
      this.$emit("push-page", {
        extends: questionPage,
        data() {
          return {
            question: question,
          };
        },
      });
    },
  },
};
</script>
