<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-back-button>戻る</v-ons-back-button>
      </div>
      <div class="center">
        <img src="img/enpr/header_logo.png" height="44" />
      </div>
      <div class="right">
        <span class="home" @click="popTopPage"><i class="fa fa-home" aria-hidden="true"></i></span>
      </div>
    </v-ons-toolbar>
    <v-ons-list>
      <v-ons-list-header class="l1">{{ print.hn }} / {{ print.pn }}</v-ons-list-header>
      <v-ons-list-item style="padding-left: 14px!important; height: initial;">
        <div style="padding: 20px">
          <div>現在のプランでは学習できません。</div>
          <div style="padding-top: 20px;">
            <v-ons-button @click="changeTab">プランを変更する</v-ons-button>
          </div>
        </div>
      </v-ons-list-item>
    </v-ons-list>
  </v-ons-page>
</template>

<script>
export default {
  methods: {
    popTopPage() {
      this.$emit("top-page");
    },
    changeTab() {
      this.$store.state.tabIndex = 3;
    }
  },
};
</script>
