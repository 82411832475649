import Vue from 'vue'
import Vuex from 'vuex'

import KOUGI from './kougi.js'
import ENPURI from './enpuri.js'
import HIGHPURI from './highpuri.js'

Vue.use(Vuex)

const GUEST_USER = {
  email: '',
  userName: 'ゲスト',
  photoURL: 'img/common/user.png',
  contractId: 0,
  subscriptionId: '',
  priceId: '',
  start: '',
  end: ''
};

export default new Vuex.Store({
  state: {
    loginUser: GUEST_USER,
    kougis: KOUGI,
    enpuris: ENPURI,
    highpuris: HIGHPURI,
    segment: 0,
    hSegment: 0,
    recordMap: {},
    enpuriMap: {},
    kougiMap: {},
    tabIndex: 0,
  },
  mutations: {
    loginUser(state, user) {
      state.loginUser = user;
    },
    logoutUser(state) {
      state.loginUser = GUEST_USER;
    }
  },
  actions: {
  },
  modules: {
  },
});
